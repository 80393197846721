function generateNewAccessKeyPageJS () {
    $('.generate-new-key-form-js').on('submit', (e) => {
        e.preventDefault()
        const $form = $('.generate-new-key-form-js')
        const formData = $form.serialize()
        $.ajax({
            url: '/generate-new-access-key',
            data: formData,
            method: 'POST'
        }).done(function (data) {
            if (data.success && data.newKey) {
                $('.generate-new-access-key-js').append(`<div class="new-key-container"><a href="javascript:;" class="new-key new-key-js">${data.newKey}</a><p>Click the link above to copy</p></div>`)
            }
        })
    })

    $('body').on('click', '.new-key-js', (e) => {
        navigator.clipboard.writeText($('.new-key-js').text()).then(function () {
            $('.new-key-js').text('Copied!')
        })
    })
}

module.exports = {
    generateNewAccessKeyPageJS
}
