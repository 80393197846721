const discountObvious = require('../../public/discountobvious.bundle')
const { defaultConfig } = require('../constants/DefaultConfig')
const GlobalUpdateFields = require('../constants/GlobalUpdateFields')
const { decodeString } = require('../../lib/utils')
const { calculateFieldsToGloballyUpdate, filterOutGlobalFieldsWithOverrides } = require('../../lib/globalUpdateUtils')
const errorMessages = {
    discountAmount: 'Please enter a number for the discount amount',
    animationDuration: 'Please enter a number for the animation duration',
    textColor: "Please enter a color's HEX code, which will look like this: #0d6efd"
}

// Used on page load or example change
function adjustExamplePosition () {
    let visibleElement
    document.querySelectorAll('.example-panel-js').forEach((ele) => {
        if (ele.offsetWidth > 0 && ele.offsetHeight > 0) {
            visibleElement = ele
        }
    })

    const bottomPosition = visibleElement.offsetTop + visibleElement.offsetHeight - 300
    $(window).on('scroll', function (e) {
        if (e.currentTarget.pageYOffset > bottomPosition) {
            visibleElement.style.position = 'fixed'
            visibleElement.style.top = '30px'
        } else {
            visibleElement.style.position = 'relative'
            visibleElement.style.top = '0px'
        }
    })
}

function decodeDiscountText () {
    $('#discountText').val(decodeString($('#discountText').val()))
    $('.discount-name-input-js').val(decodeString($('.discount-name-input-js').val()))
}

// Used once on page load to create the dropdown
function setupConfigSelector (configs) {
    if (configs.length > 0) {
        configs.forEach((config, index) => {
            if (config.discountName) {
                $('.pagination-select-js').append(`<option value="${index}">${config.discountName}</option>`)
            } else {
                $('.pagination-select-js').append(`<option value="${index}">Discount #${index}</option>`)
            }
        })
    } else {
        $('.pagination-js').hide()
    }
}

// Performs the UI interaction after the user has made the decision to save or throw out their changes
// Updates page data as needed
function doDelayedUserAction (selector, config) {
    if (selector === '.pagination-right-js' || selector === '.pagination-left-js') {
        $('body').trigger('update-config-data', config)
        $(selector).click()
    } else if (selector === '.pagination-select-js') {
        $('body').trigger('update-config-data', config)
        $('body').trigger('update-config-ui', Number($('.pagination-select-js').val()))
    }
}

/**
 * This function executes when the user updates the page or when code updates the page. It executes frequently.
 * It submits user settings to the backend, but typically doesn't save to database.
 * Afterwards, it triggers updates to the UI.
 * @param {Object} e browser event that triggered submit, always cancelled if provided
 * @param {true} shouldUpdateS3 whether or not we save this config to the database
 */
function submitForm (e, shouldUpdateS3) {
    if (e) {
        e.preventDefault()
    }

    if (!$('#discountAmount').val()) {
        return
    }

    $('.form-error-message').text('')
    $('.strike').removeClass('strike')
    let form = $('#save-user-config').serialize()
    if (shouldUpdateS3) {
        form = form + ('&shouldUpdateS3=on')
    }

    $.ajax({
        url: '/admin/save-user-config',
        data: form,
        method: 'POST'
    }).success(function (data) {
        $('body').trigger('update-serialized-config', JSON.stringify(data))
        if (data.id) $('.config-id-js').val(data.id)

        if (shouldUpdateS3 && data.applyStylingGlobally) {
            $('body').trigger('update-global-fields', data)
        }

        if (!data.attemptedAction) {
            $('.take-action-text').remove()
            $('.take-action-text-savings').remove()
            $('.estimate-disclaimer').remove()
            $('.do-badge').remove()
            $('[data-do-observed]').removeAttr('data-do-observed')
            $('.take-action-text').removeClass('yes-animation fade-in zoom-in bounce-in')
            data.showSavings ? $('.savings-type-js').show() : $('.savings-type-js').hide()
            discountObvious(data)

            if (data.userLoggedIn && shouldUpdateS3) {
                $('body').trigger('update-config-data', data)

                if (data.experimentCode) {
                    $('.experiment-code-js').text(data.experimentCode)
                    $('.ab-block-js').show()
                } else {
                    $('.saved-block-js').show()
                }

                $('.saved-url-js').text(data.fileName)
                $('.saved-container-js').show()
                document.querySelector('.saved-container-js').scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
            }
        } else {
            doDelayedUserAction(data.attemptedAction.trim(), data)
        }
    }).error(function (error) {
        if (error && error.responseJSON && error.responseJSON.message) {
            const errorBlock = document.querySelector('.form-error-message')
            errorBlock.textContent = error.responseJSON.message
            errorBlock.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
        }
    })
}

/**
 * This is the main function to programmatically update the form
 * @param {Object} discountObviousOptions
 */
function updateUIfromConfig (discountObviousOptions) {
    $('.saved-container-js').hide()
    const checkboxes = document.querySelectorAll('form input[type="checkbox"')
    for (const checkbox of checkboxes) {
        const optionsValue = discountObviousOptions[checkbox.name]
        if ((optionsValue && !checkbox.checked) || (!optionsValue && checkbox.checked)) {
            checkbox.click()
        }
    }

    const inputs = document.querySelectorAll('form input')
    for (const input of inputs) {
        if (input.name === 'discountName') {
            input.value = discountObviousOptions[input.name] ? discountObviousOptions[input.name] : discountObviousOptions.id
        } else if (input.type !== 'radio' && input.type !== 'checkbox') {
            if (discountObviousOptions[input.name] && discountObviousOptions[input.name] !== 'undefined') {
                input.value = discountObviousOptions[input.name]
            }
        } else if (input.type === 'radio') {
            if (discountObviousOptions[input.name] === input.value) {
                input.click()
            }
        }
    }

    const selectFields = document.querySelectorAll('form select')
    for (const selectField of selectFields) {
        selectField.value = discountObviousOptions[selectField.name]
    }

    if (discountObviousOptions.discountJavascript) {
        $('#discountJavascript').val(discountObviousOptions.discountJavascript)
    }

    submitForm(null, false)
}

/**
 * Responsible for hiding or showing the AB controls depending on whether this config is a real config or an AB variant
 * @param {Object} config
 */
function setABControls (config) {
    if (config.abref) {
        $('.ab-testing-link-js').hide()
        $('.ab-testing-form-group-js').hide()
        $('.ab-notifier').show()
    } else {
        $('.ab-testing-link-js').show()
        $('.ab-testing-form-group-js').show()
        $('.ab-notifier').hide()
    }
}

/**
 * Sets both the UI and the form values related to the global boxes, ie, Discount Color, Show Badges, etc.
 * @param {Object} currentConfig the current config on the page
 * @param {Boolean} setAll if we should set all values to true
 */
function setGlobalFormValuesAndUI (currentConfig, setAll) {
    const globalUpdateKeys = Object.keys(GlobalUpdateFields)

    globalUpdateKeys.forEach((key) => {
        const optionButtonSelector = `.global-style-option[data-href="${key}"]`
        const inputSelector = `input[name=${key}]`
        if (setAll || (currentConfig && currentConfig[key])) {
            $(optionButtonSelector).show()
            $(inputSelector).prop('checked', true)
        } else {
            $(optionButtonSelector).hide()
            $(inputSelector).prop('checked', false)
        }
    })
}

/**
 * Responsible for updating the UI to represent global styling, disables inputs if necessary
 * @param {Object} configs JSON representation of all the user's configs
 */
function setGlobalStylingIfNecessary (configs) {
    const globalConfig = { hasGlobalConfig: false, config: null }
    const currentConfigID = $('.config-id-js').val()
    let currentConfig
    configs.forEach((config) => {
        const isCurrentID = config.id === currentConfigID
        if (isCurrentID) {
            currentConfig = Object.assign({}, config)
        }

        if (config.applyStylingGlobally) {
            globalConfig.hasGlobalConfig = true
            globalConfig.config = config
        } else if (isCurrentID && config.overrides && config.overrides.length > 0) {
            globalConfig.overrides = config.overrides
        }
    })

    if (globalConfig.hasGlobalConfig) {
        const currentConfigIsGlobal = globalConfig.config.id === currentConfigID
        const fieldsWithGlobalUpdates = filterOutGlobalFieldsWithOverrides(calculateFieldsToGloballyUpdate(globalConfig.config), globalConfig.overrides)
        fieldsWithGlobalUpdates.forEach((field) => {
            const $formField = $(`form *[name="${field}"]`)
            if (!$formField.hasClass('disabled-by-global-styling') && !currentConfigIsGlobal) {
                // Need to use this class instead of the disabled prop because otherwise the form won't submit it and things get weird
                $formField.addClass('disabled-by-global-styling')
                $formField.parent().append(`<a class="override-link override-js">Disabled by global styling on ${globalConfig.config.discountName}. Click here to override.</a>`)
            } else if ($formField.hasClass('disabled-by-global-styling') && currentConfigIsGlobal) {
                $formField.removeClass('disabled-by-global-styling')
                $formField.parent().find('.override-link').remove()
            }
        })

        setGlobalFormValuesAndUI(currentConfig)

        return globalConfig.config
    } else {
        return null
    }
}

/**
 * These submitForm listeners allow for the live refresh of the preview product tiles on the user settings page
 * Gets unset when we are doing programattic updates and then reset afterwards
 */
function setDynamicEventListeners () {
    $('form textarea').on('blur', function (e) {
        submitForm(e, false)
    })

    $('form select').on('change', function (e) {
        submitForm(e, false)
    })

    $('form input').on('input', function (e) {
        submitForm(e, false)
    })
}

/**
 * Responsible for unsetting dynamic event listeners while we are performing a programmatic update
 * This ensures we don't get into a weird state where the user is updating the form and we are also updating it
 */
function unsetDynamicEventListeners () {
    $('form textarea').off('blur')

    $('form select').off('change')

    $('form input').off('input')
}

function userSettingsPageJS () {
    $.ajax({
        url: '/admin/get-configs',
        method: 'GET'
    }).success(function (configs) {
        $('body').trigger('initial-data', JSON.stringify(configs))
    }).error(function (error) {
        console.log(error)
    })

    // Important data structures.
    // The configs array saves all of the user's configs on the front-end
    let configs = []
    // formEvents determines whether we ask to save changes, if both conditions are two we do
    const formEvents = { condition1: false, condition2: false }

    // Custom event listeners. Primarily intended to update data for use by the front-end
    $('body').on('initial-data', (e, data) => {
        configs = configs.concat(JSON.parse(data))
        setupConfigSelector(configs)
        $('body').trigger('update-config-ui', 0)
        discountObvious(configs[0])
        setDynamicEventListeners()
    })

    $('form input, form select').on('focus', () => {
        formEvents.condition1 = true
    })

    $('form').on('keypress', () => {
        if (formEvents.condition1) {
            formEvents.condition2 = true
        }
    })

    $('form input, form select').on('click', () => {
        if (formEvents.condition1) {
            formEvents.condition2 = true
        }
    })

    $('body').on('update-config-ui', (e, configIndex) => {
        updateUIfromConfig(configs[configIndex])
        $('.pagination-select-js').val(configIndex)
        setABControls(configs[configIndex])
        setGlobalStylingIfNecessary(configs)
        setDynamicEventListeners()
        decodeDiscountText()
    })

    $('body').on('update-config-data', (e, data) => {
        formEvents.condition1 = false
        formEvents.condition2 = false
        configs.forEach((config, index) => {
            if (config.id === data.id) {
                configs[index] = Object.assign({}, data)
            }
        })
    })

    // Needed only to prevent a refresh on the front-end when the user updates a global config and navigates to a different config
    $('body').on('update-global-fields', (e, data) => {
        const globalConfig = data
        const allGlobalUpdateFields = calculateFieldsToGloballyUpdate(globalConfig)

        configs.forEach((config, index) => {
            if (config.id !== globalConfig.id) {
                const fieldsToGloballyUpdate = filterOutGlobalFieldsWithOverrides(allGlobalUpdateFields, config.overrides)
                fieldsToGloballyUpdate.forEach((field) => {
                    config[field] = globalConfig[field]
                })
            }
        })
    })

    $('body').on('add-to-config-data', (e, data) => {
        configs.push(data)
    })

    $('body').on('delete-config', (e, data) => {
        const formBody = { id: data }
        $.ajax({
            url: '/admin/delete-user-config',
            data: formBody,
            method: 'POST'
        }).success(function (data) {
            // maybe show a toast message?
            location.reload()
        }).error(function (data) {
            // maybe show an angry toast message?
            console.log(data)
            console.log(JSON.stringify(data))
        })
    })

    $('.pagination-select-js').on('change', (e) => {
        unsetDynamicEventListeners()
        const currentValue = Number($('.pagination-select-js').val())
        if (formEvents.condition1 && formEvents.condition2) {
            $('.attempted-action-js').val('.pagination-select-js')
            $('.save-prompt').modal('show')
        } else if (configs[currentValue]) {
            $('body').trigger('update-config-ui', currentValue)
        }
    })

    $('.pagination-direction-button-js').on('click', function (e) {
        unsetDynamicEventListeners()
        const paginationDirection = $(this).hasClass('pagination-left-js') ? 'left' : 'right'
        const paginationValue = Number($('.pagination-select-js').val())
        if (formEvents.condition1 && formEvents.condition2) {
            e.preventDefault()
            const attemptedAction = paginationDirection === 'left' ? '.pagination-left-js' : '.pagination-right-js'
            $('.attempted-action-js').val(attemptedAction)
            $('.save-prompt').modal('show')
            return
        }

        let updatedValue
        // Decrement or loop
        if (paginationDirection === 'left') {
            if (paginationValue - 1 < 0) {
                updatedValue = configs.length - 1
            } else {
                updatedValue = paginationValue - 1
            }
        } else { // Increment or loop
            if (paginationValue + 1 === configs.length) {
                updatedValue = 0
            } else {
                updatedValue = paginationValue + 1
            }
        }

        $('.pagination-select-js').val(updatedValue)
        $('body').trigger('update-config-ui', updatedValue)
    })

    $('.save-button').on('click', function (e) {
        if ($(this).hasClass('yes-save-js')) {
            submitForm(e, true)
        } else {
            doDelayedUserAction($('.attempted-action-js').val(), configs)
        }
    })

    $('.update-button-js').on('click', function (e) {
        submitForm(e, false)
    })

    $('.user-settings-submit').click(function (e) { // The only one that actually submits to the Database
        submitForm(e, true)
    })

    function createNewConfig (newName) {
        unsetDynamicEventListeners()
        const newConfig = Object.assign({}, defaultConfig)
        const currentLengthPagination = $('.pagination-select-js option').length
        const discountName = newName || `Discount #${currentLengthPagination + 1}`
        newConfig.discountName = discountName
        $('.pagination-select-js').append(`<option value="${currentLengthPagination}">${discountName}</option>`)
        $('.discount-name-input-js').val(discountName)

        $('.config-id-js').val('')
        $('.pagination-select-js').val(currentLengthPagination)

        $('body').trigger('add-to-config-data', newConfig)
        $('body').trigger('update-config-ui', currentLengthPagination)
    }

    $('#newConfig').on('click', function (e) {
        e.preventDefault()
        createNewConfig()
    })

    $('.create-ab-discount-js').on('click', function (e) {
        e.preventDefault()
        $('.ab-ref-js').val($('.config-id-js').val())
        const currentName = $('.discount-name-input-js').val()
        const dateObj = new Date()
        const abName = `Test of ${currentName} - ${dateObj.toLocaleDateString()}`

        createNewConfig(abName)

        $('.advanced-settings-header-js').click()
        $('html, body').animate({
            scrollTop: $('.pagination-js').offset().top - 100
        }, 200)
    })

    $('#deleteConfig').on('click', function (e) {
        e.preventDefault()
        unsetDynamicEventListeners()
        const uuidToDelete = $('.config-id-js').val()
        $('body').trigger('delete-config', uuidToDelete)
    })

    $('#save-user-config input').on('blur', function () {
        if ($(this).is(':invalid')) {
            const key = $(this).attr('name')
            $(this).closest('.form-group').find('.error-message').text(errorMessages[key])
        }
    })

    $('input[name="takeActionDiscount"], .discount-text-type input[type=radio]').on('change', function (e) {
        const checked = $('input[name="takeActionDiscount"]').is(':checked')
        const discountTextType = $('.discount-text-type input[type=radio]:checked').val()
        if (checked && discountTextType === 'plainText') {
            $('.discount-text-type-warning').addClass('show')
        } else {
            $('.discount-text-type-warning').removeClass('show')
        }
    })

    $('input[name="takeActionDiscount"]').one('click', function (e) {
        const checked = $('input[name="takeActionDiscount"]').is(':checked')
        if (checked) {
            $('input[name="strikethrough"]').prop('checked', false)
        }
    })

    $('input[name="applyStylingGlobally"]').on('click', function (e) {
        // Shows or hides all global inputs depending on whether the checkbox is checked
        setGlobalFormValuesAndUI(null, $(this).is(':checked'))
    })

    $('.change-promotion-name-js').on('click', function (e) {
        const $discountNameInput = $('.discount-name-input-js')
        const discountNameType = $discountNameInput.attr('type')
        if (discountNameType === 'hidden') {
            $discountNameInput.attr('type', 'text')
            $('.discount-name-explain-js').show()
        } else if (discountNameType === 'text') {
            $discountNameInput.attr('type', 'hidden')
            $('.discount-name-explain-js').hide()
        }
    })

    function translateOverrideValue ($input, name, value) {
        if ($input.attr('type') === 'radio') {
            return value
        } else {
            return name
        }
    }

    $('body').on('click', '.override-js', function (e) {
        const $input = $(this).parent().find('input, select')
        const $overridesInput = $('.overrides-input-js')
        const currentOverrideValue = $overridesInput.val()
        const valueToAdd = translateOverrideValue($input, $input.attr('name'), $input.val())
        currentOverrideValue && currentOverrideValue !== 'undefined' ? $overridesInput.val(`${currentOverrideValue}${valueToAdd},`) : $overridesInput.val(`${valueToAdd},`)
        $input.removeClass('disabled-by-global-styling')
        $(this).remove()
    })

    $('#save-user-config input').on('focus', function () {
        $(this).closest('.form-group').find('.error-message').text('')
    })

    $('.explain-more-js').on('click', function () {
        const target = $(this).data('target')
        $(target).modal('show')
    })

    $('.show-badge-js').on('change', function () {
        $('.badge-options-js').toggle()
    })

    $('.animation-select-js').on('change', function () {
        $('.animation-duration-input-js').val($('.animation-select-js').val())
    })

    $('.plain-text-link-js').on('click', function () {
        $('.special-discount-type').removeClass('d-flex').hide()
        $('.success-condition-js').removeClass('d-flex').hide()
    })

    $('.discount-type-link-js').on('click', function () {
        $('.special-discount-type').removeClass('d-flex').hide()
        $('.special-discount-type-link-js').addClass('d-flex')
        $('.success-condition-js').addClass('d-flex')
    })

    $('.discount-type-javascript-js').on('click', function () {
        $('.special-discount-type').removeClass('d-flex').hide()
        $('.special-discount-type-javascript-js').addClass('d-flex')
        $('.success-condition-js').addClass('d-flex')
    })

    $('.mobile-show-badge-js').on('click', function () {
        $('.mobile-show-badges-modified-js').val(true)
    })

    $('.mobile-show-badge-js').on('change', function () {
        $('.mobile-badge-options-js').toggle()
    })

    $('.page-select-js').on('change', function () {
        const selectedPanel = '.' + $(this).val() + '-js'
        const paginationValue = $('.pagination-select-js').val()
        const currentValue = paginationValue ? Number(paginationValue) : 0
        $('.example-panel-js').hide()

        if (selectedPanel.includes('desktop')) {
            $('.user-settings-example-js').addClass('col-lg-6').removeClass('col-lg-4')
            $('.user-settings-panel-js').addClass('col-lg-6').removeClass('col-lg-8')
            $(selectedPanel).css('display', 'flex')
        } else {
            $('.user-settings-example-js').addClass('col-lg-4').removeClass('col-lg-6')
            $('.user-settings-panel-js').addClass('col-lg-8').removeClass('col-lg-6')
            $(selectedPanel).show()
        }

        $(window).off('scroll')
        $('body').trigger('update-config-ui', currentValue)
        adjustExamplePosition()
    })

    $('.animation-duration-switch-js').on('click', function () {
        $('.animation-duration-input-js').toggle()
        $('.animation-select-js').toggle()
        $('.animation-duration-text-2').toggle()
        $('.animation-duration-text-1').toggle()
        $('.animation-duration-small-label-js').toggle()
    })

    $('.sitewide-input-js').on('click', function () {
        $('.sku-block-js').toggle()
    })

    // NOTE - no idea why this isn't working ootb with bootstrap. Necessary as of 09/18/22.
    $('button[data-dismiss="modal"').on('click', function () {
        $(this).closest('.modal').modal('hide')
    })

    $('.experiment-code-js, .click-to-copy').on('click', function () {
        navigator.clipboard.writeText($('.experiment-code-js').text()).then(function () {
            $('.click-to-copy').hide()
            $('.successfully-saved').show()
        })
    })

    $('#discountAmount').on('input', function () {
        if ($('#discountAmount').val().includes('%')) {
            $('#discountType').val('percentage')
            $($('#discountAmount').val($('#discountAmount').val().replaceAll('%', '')))
        }
    })

    if (!($('input.sitewideInput').is(':checked'))) {
        $('#skuCollapseContent').addClass('show')
    }

    $('.checkbox-label-js').on('click', function () {
        $(this).parent().find('input').trigger('click')
    })

    $('.global-style-option').on('click', function () {
        $(this).hide()
        const relatedInput = $(this).data('href')
        const inputSelector = `input[name=${relatedInput}]`
        $(inputSelector).prop('checked', false)
    })

    $('.ab-testing-link-js').on('click', function () {
        const loggedIn = $(this).data('loggedin')
        if (!loggedIn) {
            $('#abNotice').modal('show')
        } else {
            $('.advanced-settings-header-js').click()
            $('input[name="abTesting"]').prop('checked', true)
            $('html, body').animate({
                scrollTop: $('#ab-testing-container').offset().top - 200
            }, 200)
        }
    })

    function toggleUrlParameter (url, parameter) {
        const regex = new RegExp(`[?&]${parameter}(=([^&#]*)|&|#|$)`)
        const results = regex.exec(url)

        if (results) {
            return url.replace(regex, '')
        } else {
            const separator = url.indexOf('?') !== -1 ? '&' : '?'
            return url + separator + parameter + '=true'
        }
    }

    $('.preview-mode-example-js').on('click', function () {
        const currentUrl = window.location.href
        const newUrl = toggleUrlParameter(currentUrl, 'dopreview')

        // TODO re-run discount obvious
        if (currentUrl !== newUrl) {
            history.replaceState({}, '', newUrl)
        }

        submitForm(null, true)
    })

    window.setTimeout(() => {
        adjustExamplePosition()
    }, 100)

    window.setTimeout(() => {
        $('.pagination-select-js option').each(function () {
            $(this).text(decodeString($(this).text()))
        })
    }, 600)
}

module.exports = {
    userSettingsPageJS
}
