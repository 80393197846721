// TODO Need an arch plan for the selectors
const defaultConfig = {
    applicable_info: {
        'customer-groups': {
            groups: [
                'Everyone',
                'Big Spenders'
            ],
            'match-mode': 'any'
        },
        inclusion_type: 'all',
        excluded_products: [
            '008884303989M',
            '008884303996M'
        ]
    },
    id: '',
    discountName: 'Discount #1',
    discountAmount: 25,
    targetEleSelectors: ['.price-item--regular', '.price .sales .value'],
    exclusivity: false,
    includedSKUs: [],
    excludedSKUs: [],
    salePriceSelectors: ['.price .strike-through', '.price .list .value'],
    productContainerSelectors: ['.product-detail', '.card-wrapper'],
    strikethrough: true,
    discountText: 'if you buy today!',
    discountTextType: 'plainText', // enum, 'plainText', 'link', 'javascript'
    textColor: '#198617',
    appearAtEnd: false,
    animationDuration: 800,
    animateWhenInView: true,
    previewMode: false,
    textAnimation: 'no-animation',
    discountType: 'percentage', // enum: 'fixed-amount', 'percentage'
    showSavings: true,
    strikeThroughColor: '#777777',
    strikeThroughHeight: '1px',
    strikeThroughRotation: '0deg',
    showSavingsType: 'showSavingsAmount', // enum: 'showSavingsAmount', 'showSavingsPercentage'
    showBadges: false,
    startDate: '',
    endDate: '',
    badgeContainerSelectors: ['.collection .media', '.product__media'],
    badgeText: 'Flash Sale',
    badgeTextSize: '13px',
    badgeBorderRadius: 5,
    badgePosition: 'do-badge-top-left',
    badgeBackgroundColor: '#198617',
    badgeTextColor: '#ffffff',
    badgeLeftOffset: 0,
    badgeRightOffset: 0,
    badgeTopOffset: 0,
    badgeBottomOffset: 0,
    mobileDiscountText: '',
    mobileBadgeText: '',
    mobileBadgeTextColor: '#ffffff',
    mobileShowBadges: false,
    mobileBadgeBackgroundColor: '#198617',
    mobileBadgeLeftOffset: 0,
    mobileBadgeRightOffset: 0,
    mobileBadgeTopOffset: 0,
    mobileBadgeBottomOffset: 0,
    discountEnabled: true,
    takeActionDiscount: false
}

const defaultAccountSettings = {
    targetEleSelectors: ['.price-item--regular', '.price .sales .value'],
    salePriceSelectors: ['.price .strike-through', '.price .list .value'],
    productContainerSelectors: ['.product-detail', '.card-wrapper'], // It would be viable to change this to product container, check for target and badge, add a disabled prop if needed
    badgeContainerSelectors: ['.collection .media', '.product__media'],
    previewMode: false
}

module.exports = {
    defaultConfig,
    defaultAccountSettings
}
