/*
* I'm not proud of this file name but I couldn't think of a better one.
* For general purpose functions that don't integrate with a third party system.
*/

function encodeString (input) {
    // Replace common code characters with an empty string
    let cleanedString = input.replace(/[<>\/{}()]/g, '')

    // Manually encode the characters ! ' ( ) *
    cleanedString = cleanedString
        .replace(/!/g, '%21')
        .replace(/'/g, '%27')
        .replace(/\./g, '%2E')
        .replace(/"/g, '%22')

    // Use encodeURIComponent to encode the rest of the characters
    return encodeURIComponent(cleanedString)
}

function decodeString (input) {
    let decodedString = decodeURIComponent(input)

    // Manually decode the characters ! ' ( ) *
    decodedString = decodedString
        .replace(/%21/g, '!')
        .replace(/%27/g, "'")
        .replace(/%2E/g, '.')
        .replace(/%22/g, '"')

    return decodedString
}

module.exports = {
    encodeString,
    decodeString
}
