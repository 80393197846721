const GlobalUpdateFields = require('../src/constants/GlobalUpdateFields')

// Returns all related fields based on booleans in a config
function calculateFieldsToGloballyUpdate (discountObviousOptions) {
    let fieldsToGloballyUpdate = ['applyStylingGlobally']

    if (discountObviousOptions.globalDiscountColor) {
        fieldsToGloballyUpdate = fieldsToGloballyUpdate.concat(GlobalUpdateFields.globalDiscountColor)
    }

    if (discountObviousOptions.globalShowBadge) {
        fieldsToGloballyUpdate = fieldsToGloballyUpdate.concat(GlobalUpdateFields.globalShowBadge)
    }

    if (discountObviousOptions.globalBadgeStyles) {
        fieldsToGloballyUpdate = fieldsToGloballyUpdate.concat(GlobalUpdateFields.globalBadgeStyles)
    }

    if (discountObviousOptions.globalAmountSaved) {
        fieldsToGloballyUpdate = fieldsToGloballyUpdate.concat(GlobalUpdateFields.globalAmountSaved)
    }

    return fieldsToGloballyUpdate
}

// Processes the overrides property on a config, and removes any fields in the array that are found there
function filterOutGlobalFieldsWithOverrides (fieldsToGloballyUpdate, overrides) {
    if (overrides && overrides.length) {
        return fieldsToGloballyUpdate.filter(item => !overrides.includes(item))
    } else {
        return fieldsToGloballyUpdate
    }
}

module.exports = {
    calculateFieldsToGloballyUpdate,
    filterOutGlobalFieldsWithOverrides
}
