require('./sass/main.scss')
const { userSettingsPageJS } = require('./js/user-settings-page')
const { homePageJS } = require('./js/home-page')
const { tryForFreePageJS } = require('./js/try-for-free')
const { setCountDown } = require('./js/cta-widget')
const { loginInterimPageJS } = require('./js/login-interim')
const { generateNewAccessKeyPageJS } = require('./js/generate-new-access-key')

$(document).ready(function () {
    if ($('.user-settings-page-js').length > 0) {
        userSettingsPageJS()
    }

    if ($('.home-page').length > 0) {
        homePageJS()
    }

    if ($('.try-for-free-page').length > 0) {
        tryForFreePageJS()
    }

    if ($('.login-interim-js').length > 0) {
        loginInterimPageJS()
    }

    if ($('.generate-new-access-key-js').length > 0) {
        generateNewAccessKeyPageJS()
    }

    setCountDown()
})
