
function tryForFreePageJS () {
    $('.try-for-free__form').on('submit', (e) => {
        e.preventDefault()
        const $form = $('#try-for-free-form')
        const formData = $form.serialize()
        $.ajax({
            url: '/admin/contact-form',
            data: formData,
            method: 'POST'
        }).done(function (data) {
            $form.hide()
            if (data.success) {
                $('.try-for-free__success-block').css({
                    opacity: 1,
                    'min-height': 300,
                    'max-height': 300
                })
            } else {
                $('.try-for-free__error-block').css({
                    opacity: 1,
                    'min-height': 300,
                    'max-height': 300
                })
            }
        })
    })

    $('.try-for-free input').on('blur', (e) => {
        const $ele = $(e.target)
        if ($ele.is(':valid')) {
            $ele.removeClass('not-valid')
        } else {
            $ele.addClass('not-valid')
        }
    })

    $('.try-for-free select').on('focus', () => {
        $('.try-for-free__form-select').removeClass('low-opacity-select')
    })
}

module.exports = {
    tryForFreePageJS
}
