function loginInterimPageJS () {
    function sendInterimPostRequest() {
        const $form = $('.access-key-form-js')
        const formData = $form.serialize()
        $.ajax({
            url: '/login-interim',
            data: formData,
            method: 'POST'
        }).done(function (data) {
            if (data.success) {
                location.href = '/login'
            } else {
                $('.error-message-js').text(data.message)
            }
        })
    }

    $('.access-key-form-js').on('submit', (e) => {
        e.preventDefault()
        sendInterimPostRequest()
    })

    $('.no-access-key-js').on('click', () => {
        sendInterimPostRequest()
    })

    $('.access-key-form-js input').on('input blur', () => {
        $('.error-message-js').text('')
    })
}

module.exports = {
    loginInterimPageJS
}