const { defaultConfig } = require('../../src/constants/DefaultConfig')
const discountObvious = require('../../public/discountobvious.bundle')

function homePageJS () {
    const options = {
        threshold: 0.1
    }

    const firstObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const discountObviousOptions = Object.assign({}, defaultConfig)
                discountObviousOptions.discountAmount = 40
                discountObviousOptions.discountText = 'if you buy today!'
                discountObviousOptions.targetEleSelectors = ['.price__regular .price-item']
                discountObviousOptions.animationDuration = 1000
                discountObviousOptions.appearAtEnd = true
                discountObviousOptions.textColor = 'rgb(255 149 149)'
                discountObviousOptions.textAnimation = 'fade-in'
                discountObviousOptions.showBadges = true
                discountObviousOptions.badgeText = 'Flash Sale'
                discountObviousOptions.badgeBackgroundColor = 'rgb(255 149 149)'
                discountObviousOptions.badgePosition = 'do-badge-top-left'
                discountObviousOptions.previewMode = false
                discountObvious(discountObviousOptions)
            }
        })
    }, options)

    const secondObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const discountObviousOptions = Object.assign({}, defaultConfig)
                discountObviousOptions.discountAmount = 15
                discountObviousOptions.strikethrough = false
                discountObviousOptions.discountText = 'if you sign up now'
                discountObviousOptions.animationDuration = 800
                discountObviousOptions.appearAtEnd = false
                discountObviousOptions.targetEleSelectors = ['.second-price .price-item']
                discountObviousOptions.showSavings = true
                discountObviousOptions.textColor = '#198617'
                discountObviousOptions.textAnimation = 'fade-in'
                discountObviousOptions.showBadges = true
                discountObviousOptions.badgeText = 'Sign Up Today'
                discountObviousOptions.discountText = 'if you sign up now!'
                discountObviousOptions.discountTextType = 'link'
                discountObviousOptions.discountLink = '/admin/'
                discountObviousOptions.badgeBackgroundColor = '#198617'
                discountObviousOptions.badgePosition = 'do-badge-bottom-left'
                discountObviousOptions.previewMode = false
                discountObvious(discountObviousOptions)
            }
        })
    }, options)

    const thirdObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const discountObviousOptions = Object.assign({}, defaultConfig)
                discountObviousOptions.discountType = 'fixed-amount'
                discountObviousOptions.discountAmount = 25
                discountObviousOptions.animationDuration = 1000
                discountObviousOptions.appearAtEnd = true
                discountObviousOptions.targetEleSelectors = ['.third-price .price-item']
                discountObviousOptions.textColor = '#FD9B2E'
                discountObviousOptions.textAnimation = 'yes-animation'
                discountObviousOptions.strikeThroughColor = '#FD9B2E'
                discountObviousOptions.strikeThroughHeight = '2px'
                discountObviousOptions.strikeThroughRotation = '-6deg'
                discountObviousOptions.discountText = 'Special just for you'
                discountObviousOptions.previewMode = false
                discountObvious(discountObviousOptions)
            }
        })
    }, options)

    const firstTarget = document.querySelector('.first-price')
    const secondTarget = document.querySelector('.second-price')
    const thirdTarget = document.querySelector('.third-price')
    firstObserver.observe(firstTarget)
    secondObserver.observe(secondTarget)
    thirdObserver.observe(thirdTarget)
}

module.exports = {
    homePageJS
}
