function setCountDown () {
    $('.cta-banner-close-js').on('click', () => {
        $('.cta-banner').removeClass('show')
        sessionStorage.setItem('alreadyDismissedFlyup', true)
    })

    const firstVisitTime = sessionStorage.getItem('firstVisitTime')
    if (!firstVisitTime) {
        sessionStorage.setItem('firstVisitTime', Math.floor(Date.now()))
    } else {
        const alreadyDismissedFlyup = sessionStorage.getItem('alreadyDismissedFlyup')
        if (!alreadyDismissedFlyup) {
            const timeout = 50000 - (Math.floor(Date.now()) - firstVisitTime)
            window.setTimeout(function () {
                $('.cta-banner').addClass('show')
            }, timeout)
        }
    }
}

module.exports = {
    setCountDown
}
